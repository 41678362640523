<template>
    <div>
        <b-modal ref="formularioEmpresa"
                 hide-footer centered size="lg"
                 title="Cadastro empresa">

            <b-container fluid>

                <b-row cols="2" class="form-empresas-licenciadas">
                    <b-col cols="12 mb-3">
                        <input-padrao classes="m-0" label="Nome *" v-model.trim="$v.nome.$model" />
                        <p class="txt-erro-validacao" v-if="clickSalvar && $v.nome.$invalid">Campo nome é obrigátório</p>
                    </b-col>

                    <b-col>
                        <input-padrao classes="m-0" label="Host Chat" v-model.trim="hostChat" />
                        <p class="txt-erro-validacao" v-if="clickSalvar && $v.hostChat.$invalid">Campo host chat é obrigátório</p>
                    </b-col>

                    <b-col>
                        <input-padrao classes="m-0" label="Host Console" v-model.trim="hostConsole" />
                    </b-col>

                    <b-col>
                        <input-padrao classes="m-0" label="Qtde máxima licenças *" tipo="number" v-model.trim="$v.numeroMaximoLicencas.$model" />
                        <p class="txt-erro-validacao" v-if="clickSalvar && $v.numeroMaximoLicencas.$invalid">Campo quantidade máxima de licenças é obrigátório</p>
                    </b-col>

                    <b-col>
                        <input-padrao classes="m-0" label="Data de Início *" tipo="date" v-model.trim="$v.inicioLicenca.$model" />
                        <p class="txt-erro-validacao" v-if="clickSalvar && $v.inicioLicenca.$invalid">Selecione uma data de início</p>
                    </b-col>

                    <b-col>
                        <input-padrao classes="m-0" label="Data de Termino *" tipo="date" v-model.trim="$v.fimLicenca.$model" />
                        <p class="txt-erro-validacao" v-if="clickSalvar && $v.fimLicenca.$invalid">Selecione uma data de termino</p>
                    </b-col>

                    <b-col>
                        <input-padrao classes="m-0" label="Usuario administrador *" v-model.trim="$v.usuarioAdmin.$model" />
                        <p class="txt-erro-validacao" v-if="clickSalvar && $v.usuarioAdmin.$invalid">Campo de usuario administrador é obrigatório</p>
                    </b-col>

                    <b-col>
                        <input-padrao classes="m-0" label="E-mail administrador *" v-model.trim="$v.emailAdmin.$model" />
                        <p class="txt-erro-validacao" v-if="clickSalvar && $v.emailAdmin.$invalid">Campo de e-mail administrador é obrigatório</p>
                    </b-col>
                    <b-col>
                        <input-select style="font-size: 14px;" class="w-100 h6" label="SD-Whats? *" :opcoes="opcoesBoleanas" v-model="SDWhats" />
                    </b-col>
                    <b-col>
                        <input-select style="font-size: 14px;" class="w-100 h6" label="Multiplas empresas? *" :opcoes="opcoesMultEmpresa" v-model="multEmpresas" />
                        <input-select style="font-size: 14px;" class="mt-3 w-100 h6" label="Ativa Mensagens Pré-Definidas? *" :opcoes="opcoesAtivaPreDefinida" v-model="ativaPreDefinida" />
                        <input-select style="font-size: 14px;" class="w-100 h6" label="Ativa Mensagens de Fila de Atendimento e Transbordo? *" :opcoes="opcoesBoleanas" v-model="mensagemFilaAtendimento" />
                    </b-col>
                    <b-col>
                        <label class="w-100 h6">Canais habilitados (integrações)</label>
                        <div style="overflow-y: scroll; height: 115px;">
                            <div class="form-check d-flex align-items-center"
                                 v-for="canal in listaTiposCanais"
                                 :key="canal.Value">
                                <input class="form-check-input w-100 h6"
                                       type="checkbox"
                                       :id="'canal-' + canal.Value"
                                       :value="canal.Value"
                                       @input="editarCanalHabilitado">
                                <label class="form-check-label" :for="'canal-' + canal.Value">
                                    {{canal.Text}}
                                </label>
                            </div>
                        </div>
                    </b-col>
                    <b-col>
                        <label class="w-100 h6">Modulos habilitados (Modulos)</label>
                        <div style="overflow-y: scroll; height: 115px;">
                            <div class="form-check d-flex align-items-center"
                                 v-for="modulo in listaModulosHabilitados"
                                 :key="modulo.Value">
                                <input class="form-check-input"
                                       type="checkbox"
                                       :id="'empresaModulos-' + modulo.Value"
                                       :value="modulo.Value"
                                       @input="editarEmpresaModulosHabilitado">
                                <label class="form-check-label" :for="'empresaModulos-' + modulo.Value">
                                    {{modulo.Text}}
                                </label>
                            </div>
                        </div>
                    </b-col>
                </b-row>

            </b-container>

            <div class="w-100 mt-4 text-right">
                <botao-modal-cancel class="d-inline mr-2" @click="fechar">Cancelar</botao-modal-cancel>
                <botao-modal-ok class="d-inline" @click="salvar">Salvar</botao-modal-ok>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import { required, email } from "vuelidate/lib/validators"
    import * as SDUtils from '@/assets/js/SDUtils'
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue'
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue'
    import InputPadrao from '@/components/inputs/InputPadrao.vue'
    import InputSelect from '@/components/inputs/InputSelect.vue'

    export default {
        name: 'FormCadastroEmpresa',
        components: {
            InputPadrao,
            BotaoModalOk,
            BotaoModalCancel,
            InputSelect
        },
        props: {
            listaTiposCanais: {
                type: Array,
                default: () => []
            },
            listaModulosHabilitados: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                id: '',
                nome: '',
                hostChat: '',
                hostConsole: '',
                numeroMaximoLicencas: 0,
                inicioLicenca: '',
                fimLicenca: '',
                usuarioAdmin: '',
                emailAdmin: '',
                integracoesHabilitadas: '',
                modulosHabilitados: '',
                SDWhats: false,
                multEmpresas: false,
                ativaPreDefinida: false,
				mensagemFilaAtendimento: false,

                opcoesBoleanas: [{ value: true, text: "Sim" }, { value: false, text: "Não" }],
                opcoesMultEmpresa: [{ value: true, text: "Sim" }, { value: false, text: "Não" }],
                opcoesAtivaPreDefinida: [{ value: true, text: "Sim" }, { value: false, text: "Não" }],

                clickSalvar: false
            }
        },
        methods: {
            show() {
                this.$refs['formularioEmpresa'].show()
            },
            fechar() {
                this.limpar();
                this.$refs['formularioEmpresa'].hide();
            },
            editar(empresa) {
                
                this.id = empresa.Id;
                this.nome = empresa.Nome;
                this.hostChat = empresa.HostChat;
                this.hostConsole = empresa.HostConsole;
                this.numeroMaximoLicencas = empresa.NumeroMaximoLicenca;
                this.inicioLicenca = SDUtils.formatarDataCalendario(empresa.InicioLicenca);
                this.fimLicenca = SDUtils.formatarDataCalendario(empresa.FimLicenca);
                this.usuarioAdmin = empresa.UsuarioAdmin;
                this.emailAdmin = empresa.EmailAdmin;
                this.SDWhats = empresa.SDWhats;
                this.multEmpresas = empresa.MultEmpresas;
                this.ativaPreDefinida = empresa.AtivaPreDefinida;
				this.mensagemFilaAtendimento = empresa.MensagemFilaAtendimento;
                this.integracoesHabilitadas = empresa.IntegracoesHabilitadas;
                this.modulosHabilitados = empresa.ModulosHabilitados;

                this.setarCanaisHabilitados(this.integracoesHabilitadas);
                this.setarEmpresaModulosHabilitados(this.modulosHabilitados)
            },
            limpar() {
                this.clickSalvar = false;

                this.id = '';
                this.nome= '';
                this.hostChat = '';
                this.hostConsole = '';
                this.numeroMaximoLicencas = 0;
                this.inicioLicenca = '';
                this.fimLicenca = '';
                this.usuarioAdmin = '';
                this.emailAdmin = '';
                this.integracoesHabilitadas = '';
                this.modulosHabilitados = '';
                this.SDWhats = false;
                this.multEmpresas = false;
                this.ativaPreDefinida = false;
				this.mensagemFilaAtendimento = false;
            },
            salvar() {
                this.clickSalvar = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                    return
                }

                let empresa = {
                    'Id': this.id,
                    'Nome': this.nome,
                    'HostChat': this.hostChat,
                    'HostConsole': this.hostConsole,
                    'NumeroMaximoLicenca': this.numeroMaximoLicencas,
                    'InicioLicenca': this.inicioLicenca,
                    'FimLicenca': this.fimLicenca,
                    'UsuarioAdmin': this.usuarioAdmin,
                    'EmailAdmin': this.emailAdmin,
                    "SDWhats": this.SDWhats ,
                    "MultEmpresas": this.multEmpresas,
                    "AtivaPreDefinida": this.ativaPreDefinida,
					"MensagemFilaAtendimento": this.mensagemFilaAtendimento,
                    'IntegracoesHabilitadas': this.integracoesHabilitadas,
                    'ModulosHabilitados': this.modulosHabilitados,
                }

                this.$store.dispatch(`empresasLicencas/${this.id ? 'editar' : 'adicionar'}`, empresa);
                this.fechar();
            },
            editarCanalHabilitado($event) {
                let arrayIdCanais = this.integracoesHabilitadas? this.integracoesHabilitadas?.split(',').filter(Boolean) : [];

                if ($event.target.checked) {
                    arrayIdCanais.push($event.target.value)
                }

                if (!$event.target.checked) {
                    arrayIdCanais = arrayIdCanais.filter(value => value !== $event.target.value)
                }

                this.integracoesHabilitadas = arrayIdCanais.sort((a, b) => a - b).toString();
            },

            editarEmpresaModulosHabilitado($event) {
                let arrayIdModulos = this.modulosHabilitados ? this.modulosHabilitados?.split(',').filter(Boolean) : [];

                if ($event.target.checked) {
                    arrayIdModulos.push($event.target.value)
                }

                if (!$event.target.checked) {
                    arrayIdModulos = arrayIdModulos.filter(value => value !== $event.target.value)
                }

                this.modulosHabilitados = arrayIdModulos.sort((a, b) => a - b).toString();
            },

            setarCanaisHabilitados(canais) {
                let canaisHabilitados = canais?.split(',');
                setTimeout(() => {
                    canaisHabilitados.forEach(value => {
                        let elementoCanalHabilitado = document.getElementById('canal-' + value);
                        if (elementoCanalHabilitado) elementoCanalHabilitado.checked = true;
                    });
                }, 300);
            },

            setarEmpresaModulosHabilitados(modulosHabilitados) {
                let empresaModulosHabilitados = modulosHabilitados?.split(',');
                setTimeout(() => {
                    empresaModulosHabilitados.forEach(value => {
                        let elementoEmpresaHabilitado = document.getElementById('empresaModulos-' + value);
                        if (elementoEmpresaHabilitado) elementoEmpresaHabilitado.checked = true;
                    });
                }, 300);
            },
        },
        validations: {
            nome: { required },
            numeroMaximoLicencas: { required },
            inicioLicenca: { required },
            fimLicenca: { required },
            usuarioAdmin: { required },
            emailAdmin: { required, email },
        },
    }
</script>

<style scoped>
    label {
        font-size: 14px;
        margin: 5px 0 2px 0;
    }

    .txt-erro-validacao {
        color: red;
        font-size: 12px;
        margin: 0;
    }

    .form-empresas-licenciadas > .col {
        margin-bottom: 15px;
    }
</style>