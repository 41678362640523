<template>
    <div>

        <b-table
            class="tabela-empresas"
            responsive
            hover
            :items="listaEmpresas"
            :fields="colEmpresas"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
        >

            <template #cell(collapse)="empresa">

                <!-- toggleDetails função bootstrap para abrir collapse -->
                <botao-icone-dinamico :classeIcone="empresa.detailsShowing ? 'fas fa-angle-down' : 'fas fa-angle-right'"
                                      @click="empresa.toggleDetails" />
            </template>

            <!-- collapse -->
            <template #row-details="empresa">

                <b-card class="collapse-card">

                    <b-row class="p-2">
                        <b-col class="px-0 py-2 mx-5">
                            <h5>Histórico de Licenças:</h5>
                        </b-col>

                        <b-table :fields="colLicencas"
                                 :items="listaLicencas.filter(x => x.EmpresaId === empresa.item.Id)"
                                 outlined small class="mx-5">

                            <template #cell(InicioLicenca)="licenca">
                                {{formatarData(licenca.item.InicioLicenca)}}
                            </template>

                            <template #cell(FimLicenca)="licenca">
                                {{formatarData(licenca.item.FimLicenca)}}
                            </template>

                            <template #cell(Alteracao)="licenca">
                                {{formatarDataHora(licenca.item.Alteracao)}}
                            </template>

                            <template v-if="adminSharkdataAutorizado" #cell(botoes)="licenca">
                                <div>
                                    <botao-tab-edit @click="$emit('editar-licenca', licenca.item.Id, null)" />
                                    <botao-tab-remove @click="$emit('remover-licenca', licenca.item.Id)" />
                                </div>
                            </template>

                        </b-table>

                    </b-row>

                </b-card>

            </template>

            <template #cell(InicioLicenca)="empresa">
                {{formatarData(empresa.item.InicioLicenca)}}
            </template>

            <template #cell(FimLicenca)="empresa">
                {{formatarData(empresa.item.FimLicenca)}}
            </template>

            <template #cell(NumeroMaximoLicenca)="empresa">
                <i class="fas fa-circle small mr-1" :style="verificaStatusEmpresaLicenciada(empresa.item.Id)"></i>
                {{empresa.item.NumeroMaximoLicenca}}
            </template>

            <template v-if="adminSharkdataAutorizado" #cell(botoes)="empresa">
                <div class="tab-coluna-botoes">

                    <botao-icone-dinamico @click="atualizarLicenca(empresa.item.Id)"
                                          classeIcone="fas fa-sync" cor="#257b92" size=".8rem" />

                    <botao-tab-edit @click="editarEmpresa(empresa.item.Id)" />

                </div>
            </template>

        </b-table>

        <div class="tabela-empresas-licenciadas-contador">
            <p class="contador-itens">{{listaEmpresas.length}} {{listaEmpresas.length > 1 ? 'empresas' : 'empresa'}}</p>
        </div>

    </div>
</template>

<script>
import * as SDUtils from '@/assets/js/SDUtils'

import BotaoTabEdit from '@/components/botoes/BotaoTabEdit.vue'
import BotaoTabRemove from '@/components/botoes/BotaoTabRemove.vue'
import BotaoIconeDinamico from '@/components/botoes/BotaoIconeDinamico.vue'

export default {
    name: 'TabelaEmpresasLicenciadas',
    
    components: {
        BotaoTabEdit,
        BotaoTabRemove,
        BotaoIconeDinamico,
    },

    props: {
        colEmpresas: {
            type: Array,
            default: () => []
        },
        listaEmpresas: {
            type: Array,
            default: () => []
        },
        colLicencas: {
            type: Array,
            default: () => []
        },
        listaLicencas: {
            type: Array,
            default: () => []
        },
        adminSharkdataAutorizado: {
            type: Boolean,
            default: () => false
        }
    },

    data() {
        return {
            sortBy: this.colEmpresas[0].key,
            sortDesc: false,
        }
    },

    methods: {
        formatarData(dataString) {
            return SDUtils.formataJsonData(dataString);
        },
        formatarDataHora(dataString) {
            let data = new Date(dataString);
            return SDUtils.dataHoraFormatada(data);
        },
        verificaStatusEmpresaLicenciada(id) {
            if (this.listaLicencas.find(x => x.EmpresaId === id && x.Ativo)) {
                return { color: 'green' }
            }
            return { color: 'red' }
        },
        editarEmpresa(id) {
            this.$emit('editar-empresa', id);
        },
        atualizarLicenca(id) {
            this.$emit('atualizar-licenca', id);
        },
    },
}
</script>

<style scoped>
.tabela-empresas {
    font-size: 0.8rem;
    margin-bottom: 0 !important;
    height: calc(100% - 90px);
}

.collapse-card {
    height: 100%;
    margin: 0px;
}

.contador-itens {
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    color: var(--cinza-5);
    margin: 0;
    padding-left: 4px;
}

.tab-coluna-botoes {
    width: 5rem;
    text-align: center;
}

.tabela-empresas-licenciadas-contador {
    background-color: var(--cinza-3);
    padding-top: 10px;
    padding-bottom: 10px;
}

::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

::-webkit-scrollbar-track {
    background: var(--cinza-4);
    border-radius: 3px;
}

::-webkit-scrollbar-thumb {
    background: var(--cinza-5);
    border-radius: 3px;
}
</style>